"use client";

import { useState } from "react";

import NextImage from "next/image";
import { twMerge } from "tailwind-merge";

import Background from "@/images/bg-auth.jpg";

type FullScreenImageLayoutProps = {
  children: React.ReactNode;
  className?: string;
};

const FullScreenImageLayout = ({
  className,
  children,
}: FullScreenImageLayoutProps) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const handleLoadingComplete = () => {
    setIsLoaded(true);
  };
  return (
    <div
      className={twMerge(
        `h-[100vh] relative flex flex-col justify-start bg-gradient-to-r from-primary-500 via-primary-600 to-primary-700 bg-vert-900`,
        className
      )}
    >
      <div className="fixed h-full w-full left-0 top-0">
        <NextImage
          src={Background}
          alt=""
          fill
          priority
          className={twMerge(
            `object-cover object-center opacity-0 duration-200 ease-in transition-all`,

            isLoaded && `opacity-100`
          )}
          onLoad={handleLoadingComplete}
        />
        <div className="absolute inset-0 bg-gradient-to-r from-vert-500 to-vert-500 mix-blend-multiply" />
      </div>
      {children}
    </div>
  );
};

export default FullScreenImageLayout;
