import { LinkProps } from "next/link";
import { createLocalizedPathnamesNavigation } from "next-intl/navigation";
import { Pathnames } from "next-intl/routing";

import { Locale } from "@efarmz/efarmz-domain-typescript";

export const locales = Object.keys(Locale).map((locale) =>
  locale.toLocaleLowerCase()
);
/*
 "/password/reset": `/password/reset`,
  "/signup": `/signup`,
  "/checkout": `/checkout`,
  "/signin": `/signin`,

*/
// The `pathnames` object holds pairs of internal
// and external paths, separated by locale.
export const pathnames = {
  "/": `/`,
  "/box": {
    fr: `/box-repas`,
    nl: `/maaltijdbox`,
  },
  "/box-pricing": {
    fr: `/tarifs-box-repas`,
    nl: `/prijzen-voor-maaltijdboxen`,
  },
  "/brands": {
    fr: `/nos-producteurs`,
    nl: `/onze-producenten`,
  },
  "/brands/[slug]": {
    fr: `/nos-producteurs/[slug]`,
    nl: `/onze-producenten/[slug]`,
  },
  "/shop": {
    fr: `/shop`,
    nl: `/shop`,
  },
  "/shop/[slug]": `/shop/[slug]`,

  //missing metadata
  "/values": {
    fr: `/nos-valeurs`,
    nl: `/onze-waarden`,
  },
  "/delivery": {
    fr: `/livraison`,
    nl: `/bezorginformatie`,
  },
  "/subscription": {
    fr: `/abonnement`,
    nl: `/het-abonnement`,
  },
  "/contact": {
    fr: `/contactez-nous`,
    nl: `/contacteer-ons`,
  },
  "/about": {
    fr: `/comment-ca-marche`,
    nl: `/hoe-werkt-het`,
  },
  "/jobs": {
    fr: `/offres-d-emploi`,
    nl: `/banen`,
  },
  "/articles": {
    fr: `/articles`,
    nl: `/artikelen`,
  },
  "/mocktails-summer-2024": {
    fr: `/les-box-mocktails-maison-efarmz`,
    nl: `/de-mocktailboxen-van-efarmz`,
  },
} satisfies Pathnames<typeof locales>;

const {
  redirect,
  usePathname,
  useRouter,
  getPathname,
  Link: LinkDefault,
} = createLocalizedPathnamesNavigation({ locales, pathnames });

const Link = (
  props: Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof LinkProps> &
    LinkProps & {
      children?: React.ReactNode;
      href: any;
      locale?: any;
    }
) => {
  return <LinkDefault {...props} prefetch={false} />;
};

export { getPathname, Link, redirect, usePathname, useRouter };
