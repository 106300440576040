"use client";
import { HiArrowRight, HiHome } from "react-icons/hi2";

import { useTranslations } from "next-intl";

import Button from "@efarmz/efarmz-react-commons/esm/components/atoms/Button";

import LogoInverted from "@/svg/LogoInverted";
import { Link } from "@/utils/navigation";
const NotFoundContainer = () => {
  const t = useTranslations(`containers/NotFoundContainer`);

  return (
    <div className="flex flex-col h-full items-center justify-center">
      <div className="sm:mx-auto sm:w-full sm:max-w-md relative">
        <LogoInverted className="mx-auto h-12 w-auto" />
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-lg relative">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 sm:py-20 relative overflow-hidden">
          <div className="text-center relative z-10">
            <p className="text-base font-semibold text-choux-500">404</p>
            <h1 className="mt-4 text-xl font-bold tracking-tight text-terreau-500 sm:text-3xl">
              {t(`title`)}
            </h1>
            <p className="mt-6 text-base leading-7 text-menthe-600 font-medium">
              {t(`subtitle`)}
            </p>
            <div className="mt-10 flex flex-col items-center justify-center gap-2">
              <Link href="/">
                <Button color="primary" size="xl" startIcon={<HiHome />}>
                  {t(`actions.go-to-home`)}
                </Button>
              </Link>
              <Link href="/contact">
                <Button color="default" size="xl" startIcon={<HiArrowRight />}>
                  {t(`actions.contact-us`)}
                </Button>
              </Link>
            </div>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 320"
            className="absolute left-0 right-0 bottom-0"
          >
            <path
              className="fill-menthe-100"
              d="m0 192 60 5.3C120 203 240 213 360 224s240 21 360 5.3c120-16.3 240-58.3 360-74.6 120-15.7 240-5.7 300 0l60 5.3v160H0Z"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default NotFoundContainer;
